export default class FieldWithErrors {
  static addFieldWithFieldErrorsListener = () => {
    document.addEventListener("DOMContentLoaded", () => {
      this.addFieldWithFieldErrors()
    })
  }

  static addFieldWithFieldErrors = () => {
    const inputTargets = ['.field_with_errors input:not([type="checkbox"])', '.field_with_errors textarea', '.field_with_errors select',]
    document.querySelectorAll(inputTargets).forEach(element => {
      element.classList.add('uk-form-danger')
    })
  }
}
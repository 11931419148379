import { Controller } from "stimulus"
import Choices from 'choices.js'
import flatpickr from "flatpickr"
import {Japanese} from "flatpickr/dist/l10n/ja"
import addSelectPlaceholderListener from "../../modules/admin/select-placeholder"

export default class extends Controller {
  static targets = [ 'flatpickr', 'choices' ]

  connect() {
    this.initFlatpickr()
    this.initChoices()
    addSelectPlaceholderListener()
  }

  initFlatpickr() {
    if (this.hasFlatpickrTarget) {
      this.flatpickrTargets.forEach((element) => {
        new flatpickr(element, this.flatpickrConfig)
      })
    }
  }

  initChoices() {
    if (this.hasChoicesTarget) {
      this.choicesTargets.forEach((element) => {
        new Choices(element, this.choicesConfig)
      })
    }
  }

  get flatpickrConfig() {
    return {
      "locale": Japanese,
      time_24hr: true,
    }
  }

  get choicesConfig() {
    return {
      shouldSort: false,
      placeholder: null,
      placeholderValue: null,
      searchPlaceholderValue: null,
      itemSelectText: '',
      removeItems: true,
      removeItemButton: true,
    }
  }
}
const addSelectPlaceholderListener = () => {
  document.querySelectorAll('select').forEach((select) => {
    addEventListener(select)
    changePlaceholder(select)
  })
}

const addEventListener = (select) => {
  select.addEventListener('change', () => {
    changePlaceholder(select)
  })
}

const changePlaceholder = (select) => {
  if (select.value === '') {
    select.style.color = '#999999'
  } else {
    select.style.color = '#000000'
  }
}

export default addSelectPlaceholderListener

import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ 'sortHandler', 'sortListUnpublished', 'sortListPublished' ]

    connect() {
        this.addSortHandlerListener()
    }

    addSortHandlerListener = () => {
        const {
            sortHandlerTargets,
            sortListUnpublishedTarget,
            sortListPublishedTarget
        } = this

        sortHandlerTargets.forEach((handler) => {
            handler.addEventListener('click', function () {
                const child = handler.parentElement.parentElement
                const target = child.parentNode === sortListUnpublishedTarget ? sortListPublishedTarget : sortListUnpublishedTarget

                target.insertAdjacentElement('beforeend', child)
            })
        })
    }
}
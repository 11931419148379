import { Controller } from "stimulus"

export default class extends Controller {
    static values = {
        managementApiEndpoint: String,
        managementToken: String,
        projectId: String,
        renderUrl: String,
        renderErrorUrl: String,
    }

    static targets = [
        'template',
        'template-no-data',
        'list'
    ]

    async connect() {
        const data = {
            user: {},
            records: []
        }

        try {
            const userRes = await this.fetchUser().then(res => res.json())
            const recordsRes = await this.fetchRecords().then(res => res.json())

            data.user = userRes.data
            data.records = recordsRes.data

            this.render(data)
        } catch {
         this.renderError()
        }
    }

    get headers() {
        const {
            managementTokenValue,
            projectIdValue,
        } = this

        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer: ${managementTokenValue}`,
            'X-Project-Id': projectIdValue
        }
    }

    render(data) {
        fetch(this.renderUrlValue, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
                'Content-Type': 'application/json'
            }
        }).then(res => res.text()).then(value => {
            this.listTarget.innerHTML = value
        })
    }

    renderError() {
        fetch(this.renderErrorUrlValue).then(res => res.text()).then(value => {
            this.listTarget.innerHTML = value
        })
    }

    fetchUser() {
        const { managementApiEndpointValue, headers } = this
        return fetch(managementApiEndpointValue, { headers })
    }

    fetchRecords() {
        const { managementApiEndpointValue, headers } = this
        return fetch(`${managementApiEndpointValue}/records`, { headers })
    }
}
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import Rails from '@rails/ujs'
import * as ActiveStorage from '@rails/activestorage'
import 'channels'
import '../modules/frontend/porting'
import FieldWithErrors from '../modules/common/field-with-error'

// UIkit
import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'

// Stimulus.js
import 'controllers'

UIkit.use(Icons)

Rails.start()
ActiveStorage.start()

// Add Rails error class into input elements.
FieldWithErrors.addFieldWithFieldErrorsListener()

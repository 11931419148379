import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "uidField" ]

    handleSubmit(e) {
        const { uidFieldTarget } = this
        e.preventDefault()

        if (window.cdp) {
            const uid = window.cdp.user.uid
            uidFieldTarget.value = uid
        }

        e.target.form.submit()
    }
}
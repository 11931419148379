import { Controller } from "stimulus"
import flatpickr from "flatpickr"
import { Japanese } from "flatpickr/dist/l10n/ja"
import * as AutoKana from 'vanilla-autokana'
import '../../modules/common/yubinbango'
import '../../modules/common/lazysizes'

export default class extends Controller {
  static targets = [ 'flatpickr' ]

  connect() {
    this.initFlatpickr()
    this.initAutoKana()
  }

  initFlatpickr() {
    if (this.hasFlatpickrTarget) {
      this.flatpickrTargets.forEach((element) => {
        new flatpickr(element, this.flatpickrConfig)
      })
    }
  }

  initAutoKana() {
    if (document.getElementById('family_name'))
      AutoKana.bind("#family_name", "#family_name_kana", { katakana: true })

    if (document.getElementById('first_name'))
      AutoKana.bind("#first_name", "#first_name_kana", { katakana: true })
  }

  get flatpickrConfig() {
    return {
      "locale": Japanese,
      time_24hr: true,
    }
  }
}
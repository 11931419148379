import jQuery from 'jquery'

;(function ($, window, document, undefined) {
  var _window = $(window)

  $(function () {
    function scroll() {
      var headerHight = 100
      $('a[href^="#"]').click(function () {
        var href = $(this).attr('href')

        var target = $(href == '#' || href == '' ? 'html' : href)

        var position = target.offset().top - headerHight
        $('html, body').animate({ scrollTop: position }, 500)
        return false
      })
    }
    function page_top() {
      var topBtn = $('.page-top')
      topBtn.hide()
      //スクロールが100に達したらボタン表示
      $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
          topBtn.fadeIn()
        } else {
          topBtn.fadeOut()
        }
      })
      //スクロールしてトップ
      topBtn.click(function () {
        $('body,html').animate(
          {
            scrollTop: 0,
          },
          500,
        )
        return false
      })
    }

    function nav() {
      /*
                    $('.menu-trigger:not(".menu-trigger-off")').on('click',function(){
                        if($(this).hasClass('is-active')){
                            $(this).removeClass('is-active');
                            $('.l-nav').removeClass('open');
                            $('.overlay').removeClass('open');
                        } else {
                            $(this).addClass('is-active');
                            $('.l-nav').addClass('open');
                            $('.overlay').addClass('open');
                        }
                    });
                    $('.overlay').on('click',function(){
                        if($(this).hasClass('open')){
                            $(this).removeClass('open');
                            $('.menu-trigger').removeClass('is-active');
                            $('.l-nav').removeClass('open');
                        }
                    });
            */
      $('.menu-trigger.pc:not(".menu-trigger-off")').on('click', function () {
        if ($(this).hasClass('is-active')) {
          $(this).removeClass('is-active')
          $('.header-nav-bottom').removeClass('is-active')
        } else {
          $(this).addClass('is-active')
          $('.header-nav-bottom').addClass('is-active')
        }
      })

      $('.menu-trigger.sp:not(".menu-trigger-off")').on('click', function () {
        if ($(this).hasClass('is-active')) {
          $(this).removeClass('is-active')
          $('.header-nav-bottom').removeClass('is-active-sp')
        } else {
          $(this).addClass('is-active')
          $('.header-nav-bottom').addClass('is-active-sp')
        }
      })

      $('.mega-btn').click(function () {
        $(this).parent('.has-mega').toggleClass('is-active')
      })
      var winH = $(window).height()

      $(window).scroll(function () {
        if ($(this).scrollTop() > 0) {
          $('.menu-trigger.pc').removeClass('is-active')
          $('.header-nav-bottom').removeClass('is-active')
        } else {
          $('.menu-trigger.pc').addClass('is-active')
          $('.header-nav-bottom').addClass('is-active')
        }
      })

      var timeoutId

      window.addEventListener('scroll', function () {
        clearTimeout(timeoutId)

        timeoutId = setTimeout(function () {
          $('.menu-trigger.pc').addClass('is-active')
          $('.header-nav-bottom').addClass('is-active')
        }, 2000)
      })
    }

    function nav_fix() {
      var adOffset = $('.main-newsbar').offset().top

      var winH = $(window).height()
      var header = $('.l-header')
      $(window).scroll(function () {
        if ($(this).scrollTop() > adOffset - 60) {
          header.addClass('is-scroll')
        } else {
          header.removeClass('is-scroll')
        }
      })
    }

    function ani() {
      $(
        '.anim-fade-1, .anim-fade-2, .anim-fade-3, .anim-fade-4, .anim-fade-5, .anim-blur-1, .anim-blur-2, .anim-blur-3, .anim-blur-4, .anim-blur-5, .anim-concept-pic-1, .anim-concept-pic-2',
      ).on('inview', function (event, isInView, visiblePartX, visiblePartY) {
        if (isInView) {
          $(this).stop().addClass('anim-start')
        } else {
          //$(this).stop().removeClass('anim-start');
        }
      })
    }

    function tabs() {
      $('.list-tab > li').click(function () {
        var num = $('.list-tab > li').index(this)
        $('.list-tab > li').removeClass('is-active')
        $('.list-tab-bottom > li').removeClass('is-active')
        $(this).addClass('is-active')
        $('.list-tab-bottom > li').eq(num).addClass('is-active')
        $('.tab').removeClass('is-active').hide()
        $('.tab').eq(num).addClass('is-active').show()
      })
      $('.list-tab-bottom > li').click(function () {
        var num = $('.list-tab-bottom > li').index(this)
        $('.list-tab-bottom > li').removeClass('is-active')
        $('.list-tab > li').removeClass('is-active')
        $(this).addClass('is-active')
        $('.list-tab > li').eq(num).addClass('is-active')
        $('.tab').removeClass('is-active').hide()
        $('.tab').eq(num).addClass('is-active').show()
        $('body,html').animate(
          {
            scrollTop: 0,
          },
          500,
        )
        return false
      })
    }
    function cor() {
      $('.main-cor .main-cor-title').click(function () {
        $(this).toggleClass('is-active')

        $(this).next('.main-cor-content').slideToggle()
      })
    }

    function sp() {}

    jQuery(document).ready(function ($) {
      $(document).ready(function () {
        nav()
        //nav_fix();
        ani()

        //mouse();
        //mouse2();
        cor()
        page_top()
        sp()
        tabs()
        scroll()
      })
    })
  })
})(jQuery, window, window.document)
